//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-532:_3760,_528,_5584,_4304,_4240,_7460,_8164,_7752;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-532')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-532', "_3760,_528,_5584,_4304,_4240,_7460,_8164,_7752");
        }
      }catch (ex) {
        console.error(ex);
      }